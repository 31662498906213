import { ReservationGuaranteeTimeline } from 'src/core/Shared/domain/Reservation.model'
import { GuaranteePolicy } from 'src/core/Shared/domain/Policies.model'
import { Translator } from '../hooks/useTrans'

const TRANSLATE_CONTEXT_KEY = 'common:guarantee_policy'

export const getGuaranteeTitle = (
  guarantee: GuaranteePolicy,
  timeline: ReservationGuaranteeTimeline,
  trans: Translator,
): string => {
  const key = guarantee.type.key
  const value = guarantee.type.value
  const options = value
    ? {
        value,
      }
    : undefined

  return trans(`${TRANSLATE_CONTEXT_KEY}_${timeline}_title_${key}`, options)
}
