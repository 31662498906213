import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { useQueryService } from 'src/ui/hooks/useQuery'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { container } from 'src/core/Shared/_di'

export const useHotelCheckInCheckoutTime = (hotelId?: string) => {
  const { queryUtils } = useApplicationRouter()
  hotelId = hotelId || queryUtils.getRawParam('hotel')

  const { data: hotelCheckInAndCheckOutTime, isValidating } = useQueryService(
    'hotel-checkIn-checkOut-time',
    isDefined(hotelId) ? [hotelId] : null,
    () => container.resolve('getCheckInAndCheckOutTime')(hotelId!),
  )

  return {
    hotelCheckInAndCheckOutTime,
    isLoadingCheckInAndCheckOut: isValidating,
  }
}
