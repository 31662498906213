import {
  availableRooms,
  personalData,
  choosePayment,
  bookingConfirmation,
} from 'analytics/views'
import { useEffect, useRef } from 'react'
import { useApplicationRouter } from './useApplicationRouter'
import { launchAnalytics } from 'src/core/Shared/infrastructure/analytics/launchAnalytics'

type PageView =
  | 'availableRooms'
  | 'personalData'
  | 'choosePayment'
  | 'bookingConfirmation'

const pageViewMap: Record<
  PageView,
  (params: any, itineraryNumber: string) => void
> = {
  availableRooms: availableRooms,
  personalData: personalData,
  choosePayment: choosePayment,
  bookingConfirmation: bookingConfirmation,
}

export const useAnalyticsPageView = (pageView: PageView) => {
  const { query } = useApplicationRouter()
  const retries = useRef(0)

  useEffect(() => {
    launchAnalytics(
      pageViewMap[pageView],
      [query],
      'view',
      query,
      retries.current,
    )
  }, [pageView, query])
}
